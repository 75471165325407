.contact-greatwall-image {
    margin: auto;
    display: block;
    border: 5px solid goldenrod;
    width: 75%;
    max-height: 650px;
}

.contact-back-ground {
    background-color: lightgray;
    padding-top: 40px;
}


.contact-ul-list {
    display: inline;
}

.contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
}

.contact-container-item {
    margin: 60px;
    width: 500px;
    background-color: white;
    border: 5px solid goldenrod;
}

.contact-container-contact {
    min-height: 100px;
}

.contact-container-open {
    min-height: 200px;
}

.contact-container-newyorkstyle {
    min-height: 300px;
}

a {
    text-decoration: none;
    color: black;
}


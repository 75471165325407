
.card-title {
    text-align: center;
    font-family: 'Georgia', 'serif';
}

.card-label {
    text-align: center;
}

.card-spacing {
    line-height: 2;
    font-family: 'Georgia', 'serif';
    margin: auto;
    width: 80%;
    padding: 5px;
    text-align: left;
}

.card-time {
    color: crimson;
    font-family: 'Georgia', 'serif';
}

.card-extra {
    font-family: 'Brush Script MT', 'cursive';
}
.menu-ul-list {
    display: inline;
}

.menu-background {
    background-color: lightgray;
}

.menu-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
}

.menu-container-item {
    margin: 30px;
    width: 700px;
    border: 4px solid #333;
    background-color: white;
}

.menu-container-item-1 {
    min-height: 100px;
}

.menu-container-item-2 {
    min-height: 200px;
}

.menu-container-item-3 {
    min-height: 300px;
}

.menu-container-item-4 {
    min-height: 400px;
}

.menu-container-lunch {
    background-color: rgb(215, 170, 46);
}

.menu-container-chef-special {
    background-color:  rgb(215, 170, 46);
}

.menu-container-dinner-combo {
    background-color:  rgb(215, 170, 46);
}
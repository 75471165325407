.footer-container {
    background: linear-gradient(rgb(138, 0, 0), red);
    padding: 0rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: goldenrod;
}

.footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid goldenrod;
}

.footer-link-wrapper {
    display: flex;
}

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: center;
    width: 160px;
    box-sizing: border-box;
}

.footer-link-items h2 {
    margin-bottom: 16px;
}

.footer-link-items > h2 {
    color: goldenrod;
}

.footer-link-items a {
    color: goldenrod;
    text-decoration: none;
    margin-bottom: 8px;
}

.footer-link-items a:hoover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
}

.footer-email-form h2 {
    margin-bottom: 32px;
}

.footer-input::placeholder {
    color: #b1b1b1;
}
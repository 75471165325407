.front-background {
    background-color: lightgrey;
}


.front-location-font-style {
    font-style: oblique;
}

.front-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}



@media screen and (max-width: 375px) {

}

@media screen and (max-width: 2000px) {
    .front-background {
        padding-bottom: 130px;
    }

    .front-location-font-style {
        padding-bottom: 60px;
    }
}


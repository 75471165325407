.front-page-background {
    background-color: lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}


.slider-image {
    width: 100%;
    max-height: 500px;
    border: 5px solid black;
}

.right-arrow {
    font-size: 3rem;
    color: black;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    padding-left: 50px;
}

.left-arrow {
    font-size: 3rem;
    color: black;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    padding-right: 45px;
}

.slide {
    opacity: 0;
    transition-duration: 5s ease;

}

.slide.active {
    /* width: 100%; */
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}


/* below 390px */
@media (max-width: 1388px) {
    .slider-image {
        width: 100%;
    }
}

/* between 394 and 414 */
/* @media (min-width: 394px) and (max-width: 414px) {
    .slider-image {
        height: 225px;
    }
} */



/* between 413 and  */
/* @media (min-width: 414px) and () {
    .slider-image{
        height: 300px;
    }
} */

/* @media (max-width: 1078px) {
    .slider-image{
        height: 400px;
        border: 5px solid red;
    }
} */

